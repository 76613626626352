//ACTUS
export const CREATE_ACTU = "CREATE_ACTU";
export const RETRIEVE_ACTUS_ETABLISSEMENT = "RETRIEVE_ACTUS_ETABLISSEMENT";
export const RETRIEVE_ALL_ACTUS_ETABLISSEMENT = "RETRIEVE_ALL_ACTUS_ETABLISSEMENT";
export const RETRIEVE_ACTUS_ETABLISSEMENT_CATEGORY = "RETRIEVE_ACTUS_ETABLISSEMENT_CATEGORY";
export const RETRIEVE_ACTUS_ETABLISSEMENT_AVAILABLE = "RETRIEVE_ACTUS_ETABLISSEMENT_AVAILABLE";
export const RETRIEVE_ACTU = "RETRIEVE_ACTU";
export const UPDATE_ACTU = "UPDATE_ACTU";
export const DELETE_ACTU = "DELETE_ACTU";

//CATEGORIES
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const RETRIEVE_CATEGORIES = "RETRIEVE_CATEGORIES";
export const RETRIEVE_CATEGORY = "RETRIEVE_CATEGORY";
export const RETRIEVE_CATEGORIES_ACTIVE = "RETRIEVE_CATEGORIES_ACTIVE";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

//ETABLISSEMENTS
export const CREATE_ETABLISSEMENT = "CREATE_ETABLISSEMENT";
export const RETRIEVE_ETABLISSEMENTS = "RETRIEVE_ETABLISSEMENTS";
export const RETRIEVE_ETABLISSEMENT = "RETRIEVE_ETABLISSEMENT";
export const UPDATE_ETABLISSEMENT = "UPDATE_ETABLISSEMENT";
export const DELETE_ETABLISSEMENT = "DELETE_ETABLISSEMENT";

//ETABLISSEMENT_CONFIG
export const CREATE_ETABLISSEMENT_CONFIG = "CREATE_ETABLISSEMENT_CONFIG";
export const RETRIEVE_ETABLISSEMENT_CONFIG = "RETRIEVE_ETABLISSEMENT_CONFIG";
export const UPDATE_ETABLISSEMENT_CONFIG = "UPDATE_ETABLISSEMENT_CONFIG";
export const DELETE_ETABLISSEMENT_CONFIG = "DELETE_ETABLISSEMENT_CONFIG";

//FEEDBACKS
export const CREATE_FEEDBACK = "CREATE_FEEDBACK";
export const RETRIEVE_FEEDBACKS = "RETRIEVE_FEEDBACKS";
export const RETRIEVE_FEEDBACKS_ETABLISSEMENT = "RETRIEVE_FEEDBACKS_ETABLISSEMENT";
export const RETRIEVE_FEEDBACK = "RETRIEVE_FEEDBACK";
export const DELETE_FEEDBACK = "DELETE_FEEDBACK";

//NOTIFICATIONS
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const RETRIEVE_NOTIFICATIONS = "RETRIEVE_NOTIFICATIONS";
export const RETRIEVE_NOTIFICATION = "RETRIEVE_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

//PAYMENTCARDS
export const CREATE_PAYMENTCARD = "CREATE_PAYMENTCARD";
export const RETRIEVE_PAYMENTCARDS_USER = "RETRIEVE_PAYMENTCARDS_USER";
export const UPDATE_PAYMENTCARD = "UPDATE_PAYMENTCARD";
export const DELETE_PAYMENTCARD = "DELETE_PAYMENTCARD";

//STRIPE
export const CREATE_STRIPE_PAYMENT = "CREATE_STRIPE_PAYMENT";

//USERS
export const CREATE_USER = "CREATE_USER";
export const RETRIEVE_USER = "RETRIEVE_USER";
export const RETRIEVE_USERS = "RETRIEVE_USERS";
export const RETRIEVE_ME = "RETRIEVE_ME";
export const UPDATE_ME = "UPDATE_ME";
export const UPDATE_PASS = "UPDATE_PASS";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";


//ROLE
export const RETRIEVE_ROLES = "RETRIEVE_ROLES";